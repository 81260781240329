<template>
  <div class="list-manage bill-manage">
    <section class="list-manage-table">
      <div class="table-title"><span class="title">抬头簿</span></div>
      <div class="bill-manage-main"  v-loading="loading">
        <div class="btn-add-bill base-btn" @click="addBillDialogVisible = true"><i class="el-icon-plus"></i>添加发票抬头</div>
        <div class="bill-manage-item" v-for="(item,index) in list" :key="index">
          <div class="info">
            <div class="info-item"><span class="label">发票抬头：</span>{{item.head_name}}</div>
            <div class="info-item"><span class="label">类型：</span> <span v-if="item.type === 1">个人/事业单位</span> <span v-else>单位</span> </div>
          </div>
          <div class="btn-group">
            <span class="btn" @click="finds(item.id)">编辑</span>
            <!-- <span class="btn btn-orange" @click="saveBill(item.id)">申请开票</span> -->
            <span class="btn btn-red" @click="deletes(item.id)" style="margin-left:8px">删除</span>
          </div>
        </div>
      </div>
    </section>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="page.pageSize"
        :current-page="page.pageNumber"
        :total="total.totalRow"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 添加发票s -->
    <el-dialog
      title="添加发票抬头"
      :visible.sync="addBillDialogVisible"
      width="800px"
      class="add-bill-dialog">
      <div class="add-bill-form">
        <div class="form-item">
          <div class="form-label">名称：</div>
          <el-input v-model="input.head_name" placeholder="请输入名称…" style="width: 350px;" clearable @blur="checkinput(9)"></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">抬头类型：</div>
          <div class="radio">
            <el-radio v-model="input.type" label="1" style="width: 120px;">个人/事业单位</el-radio>
            <el-radio v-model="input.type" label="2" style="width: 200px;">单位</el-radio>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">税号：</div>
          <el-input v-model="input.taxpayer_no" placeholder="请输入税号…" style="width: 350px;" clearable @blur="checkinput(1)"></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">单位地址：</div>
          <el-input v-model="input.company_address" placeholder="请输入单位地址…" style="width: 350px;" clearable @blur="checkinput(2)"></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">电话号码：</div>
          <el-input v-model="input.phone" placeholder="请输入电话号码…" style="width: 350px;" clearable @blur="checkinput(3)"></el-input>
        </div>
        <div class="form-item form-input-pr60">
          <div class="form-label">开户银行：</div>
          <el-input v-model="input.bank" placeholder="请输入开户银行…" style="width: 350px;" clearable @blur="checkinput(4)"></el-input>
          <i class="icon bill-icon-location"></i>
        </div>
        <div class="form-item form-input-pr60">
          <div class="form-label">银行账户：</div>
          <el-input v-model="input.bank_account" placeholder="请输入银行账户…" style="width: 350px;" clearable @blur="checkinput(5)"></el-input>
          <i class="icon bill-icon-cal cap" ></i>
        </div>
        <div class="form-item">
          <div class="form-label">收件人姓名：</div>
          <el-input v-model="input.receiv_name" placeholder="请输入收件人姓名…" style="width: 350px;" clearable @blur="checkinput(6)"></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">收件单位地址：</div>
          <el-input v-model="input.receiv_address" placeholder="请输入收件单位地址…" style="width: 350px;" clearable @blur="checkinput(7)"></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">收件电话号码</div>
          <el-input v-model="input.receiv_phone" placeholder="请输入收件电话号码…" style="width: 350px;" clearable @blur="checkinput(8)"></el-input>
        </div>
      </div>
      <div class="footer-btn"><span class="base-btn" @click="save">申请发票头</span></div>
    </el-dialog>
    <!-- 添加发票e -->
    <!-- 查看编辑发票s -->
    <el-dialog
      title="查看发票"
      :visible.sync="addBillDialogVisible1"
      width="800px"
      class="add-bill-dialog">
      <div class="add-bill-form">
        <div class="form-item">
          <div class="form-label">名称：</div>
          <el-input v-model="info.head_name" placeholder="请输入名称…" style="width: 350px;" clearable ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">抬头类型：</div>
          <div class="radio">
            <el-radio v-model="info.type" :label="1" style="width: 120px;">个人/事业单位</el-radio>
            <el-radio v-model="info.type" :label="2" style="width: 200px;">单位</el-radio>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">税号：</div>
          <el-input v-model="info.taxpayer_no" placeholder="请输入税号…" style="width: 350px;" clearable ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">单位地址：</div>
          <el-input v-model="info.company_address" placeholder="请输入单位地址…" style="width: 350px;" clearable ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">电话号码：</div>
          <el-input v-model="info.phone" placeholder="请输入电话号码…" style="width: 350px;" clearable ></el-input>
        </div>
        <div class="form-item form-input-pr60">
          <div class="form-label">开户银行：</div>
          <el-input v-model="info.bank" placeholder="请输入开户银行…" style="width: 350px;" clearable ></el-input>
          <i class="icon bill-icon-location"></i>
        </div>
        <div class="form-item form-input-pr60">
          <div class="form-label">银行账户：</div>
          <el-input v-model="info.bank_account" placeholder="请输入银行账户…" style="width: 350px;" clearable ></el-input>
          <i class="icon bill-icon-cal cap" ></i>
        </div>
        <div class="form-item">
          <div class="form-label">收件人姓名：</div>
          <el-input v-model="info.receiv_name" placeholder="请输入收件人姓名…" style="width: 350px;" clearable ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">收件单位地址：</div>
          <el-input v-model="info.receiv_address" placeholder="请输入收件单位地址…" style="width: 350px;" clearable ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">收件电话号码：</div>
          <el-input v-model="info.receiv_phone" placeholder="请输入收件电话号码…" style="width: 350px;" clearable ></el-input>
        </div>
      </div>
      <div class="footer-btn" style="display:flex;justify-content: space-around">
        <span class="base-btn" @click="finds(info.id,2)" style="margin-left:40px;">取消</span>
        <span class="base-btn" @click="edit()" style="margin-right:60px;">保存</span>
      </div>
    </el-dialog>
    <!-- 查看编辑发票e -->
  </div>
</template>

<script>

import {rec_list,rec_add,rec_find,rec_edit,rec_delete,rec_save} from '@/api/contract'
import { getuser } from "@/api/user"
  export default {
    data() {
      return {
        addBillDialogVisible: false,
        addBillDialogVisible1: false,
        // 添加发票头输入内容
        input: {
          consumer_id : null,
          head_name : "",
          taxpayer_no : "",
          company_address : "",
          bank : "",
          bank_account:"",
          phone : "",
          receiv_name: "",
          receiv_address : "",
          receiv_phone : "",
          type : '1'
        },
        page: {
          pageNumber: 1,
          pageSize: 10,
        },
        total:{
          totalPage:null,
          totalRow:null
        },
        list:[],
        user:null,
        // 发票头详情
        info:{
          "del_flag":"1",
          "receiv_address":"天帝大道333号",
          "remark":null,
          "type":1,
          "company_address":"福州",
          "consumer_id":1,
          "taxpayer_no":"0024354",
          "bank":"天地银行",
          "phone":"130555555555",
          "head_name":"天帝",
          "id":1,
          "receiv_phone":"13055555555555555555",
          "bank_account":"66666666666666666666",
          "receiv_name":"天帝"
        },
        order_id_array:[],
        all_rental_fee:null,
        checkinputs:[false,false,false,false,false,false,false,false,false,false],
        loading:true
      };
    },
    created(){
      this.getlist();
      this.user = getuser();
      this.input.consumer_id = this.user.id
      // 获取所有值(订单id数组、  全部金额)
      this.order_id_array = this.$route.query.order_id_array
      this.all_rental_fee = this.$route.query.all_rental_fee
    },
    methods: {
      handleSizeChange(val) {
        this.page.pageSize = val
        this.getlist()
      },
      handleCurrentChange(val) {
        this.page.pageNumber = val
        this.getlist()
      },
      // 获取全部
      getlist(){
        rec_list({inputs:{},pagination:this.page}).then(res => {
          this.list = res.data.list
          this.total.totalPage = res.data.totalPage
          this.total.totalRow = res.data.totalRow
          this.loading = false
          let go = sessionStorage.getItem('go')
          if(go == 1) {
              this.addBillDialogVisible = true
              sessionStorage.setItem('go',0)
          }
        })
      },
      // 添加发票头
      save(){
        if(this.checkinputs[0]) {
          rec_add(this.input).then(res => {
          if(res.data == 1) {
            this.$message({
            message: '添加发票抬头成功！',
            type: 'success'
            });
          }
          this.input.head_name = ''
          this.input.taxpayer_no = ''
          this.input.company_address = ''
          this.input.bank = ''
          this.input.bank_account = ''
          this.input.phone = ''
          this.input.receiv_name = ''
          this.input.receiv_address = ''
          this.input.receiv_phone = ''
          this.input.type = '1'
          this.addBillDialogVisible = false
          this.getlist();
          let goto = sessionStorage.getItem('goto')
          if(goto && goto == 1) {
            setTimeout(() => {
            this.$router.go(-1)
            sessionStorage.setItem('goto',0)
          }, 1000);
          }
        })
        }else { this.$message({ message: '请输入所有字段' , type: 'warning'});}
      },
      // 测试
      // saves(){
      //   console.log(this.input);
      // }
      // 获取选中的发票头
      finds(ids,num = 1){
        rec_find({id:ids}).then(res => {
          this.info = res.data
        })
        if(num == 1) {
          this.addBillDialogVisible1 = true
        }else {
          this.addBillDialogVisible1 = false
        }

      },
      // 修改
      edit() {
        this.$confirm('请确认修改的内容是否正确', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          rec_edit(this.info).then(res => {
          if(res.status === 200) {
            this.$message({
            showClose: true,
            message: '修改发票抬头成功！',
            type: 'success'
             });
            this.addBillDialogVisible1 = false
          }
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
        
      },
      // 删除
      deletes(ids) {
        this.$confirm('此操作将永久删除该发票抬头, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          rec_delete({id:ids}).then(res => {
          if(res.data == 1) {
            this.$message.success('删除抬头成功！')
          }
          this.getlist()
          // location.reload()
        })
      }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 申请发票
      saveBill(id) {
        this.$confirm('是否确认使用此抬头进行开票', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if(id) {
            // 用户id、订单id数组、抬头id、全部金额、
            rec_save({consumer_id:this.user.id,order_id_array:this.checkedCities,receipt_header:id}).then(res => {
              if(res.status === 200 ) {
                this.$message({
                message: '申请发票成功！',
                type: 'success'
              });
              this.$router.push('/personalCenter/applyBill').catch(()=>{})
              }
            })
          }
        }).catch(() => { });
      },
      // 判断是否输入
      checkinput(i) {
        var myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
        switch (i) {
          case 1:
            if(this.input.taxpayer_no == '') { this.$message({ message: '税号不能为空' , type: 'warning'});}else { this.checkinputs[i] = true}
            break;
          case 2:
          if(this.input.company_address == '') { this.$message({ message: '单位地址不能为空' , type: 'warning'});}else { this.checkinputs[i] = true}
            break;
          case 3:
          if(this.input.phone == '') { this.$message({ message: '电话号码不能为空' , type: 'warning'});}
          else if (!myreg.test(this.input.phone)) { this.$message({ message: '请输入正确的电话号码' , type: 'warning'}); }else { this.checkinputs[i] = true}
            break;
          case 4:
          if(this.input.bank == '') { this.$message({ message: '开户银行不能为空' , type: 'warning'});}else { this.checkinputs[i] = true}
            break;
          case 5:
          if(this.input.bank_account == '') { this.$message({ message: '银行账号不能为空' , type: 'warning'});}else { this.checkinputs[i] = true}
            break;
          case 6:
          if(this.input.receiv_name == '') { this.$message({ message: '收件人姓名不能为空' , type: 'warning'});}else { this.checkinputs[i] = true}
            break;
          case 7:
          if(this.input.receiv_address == '') { this.$message({ message: '收件单位地址不能为空' , type: 'warning'});}else { this.checkinputs[i] = true}
            break;
          case 8:
          if(this.input.receiv_phone == '') { this.$message({ message: '收件电话不能为空' , type: 'warning'});}
          else if (!myreg.test(this.input.phone)) { this.$message({ message: '请输入正确的收件电话' , type: 'warning'}); }else { this.checkinputs[i] = true}
            break;
          case 9:
          if(this.input.head_name == '') { this.$message({ message: '名称不能为空' , type: 'warning'});}else { this.checkinputs[i] = true}
          break;
          default:
          this.checkinputs[0] = true
          break;
        }
        for (let index = 1; index < this.checkinputs.length; index++) {
          if (this.checkinputs[index]) {
            this.checkinputs[0] = true
          }else { this.checkinputs[0] = false;break;  
          }
        }
      }
    }
  }
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  border-radius: 10px;
}
</style>
